<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 70%;display: flex;">
					<!-- 功能名称  -->
					<el-input class="hunt" v-model="input" placeholder="请输入功能名称" size="large" @input="orangutan" clearable />
					<!-- 功能类型 -->
					<el-select class="hunt" v-model="type" placeholder="请选择功能类型" clearable @clear="empty"
						size="large">
						<el-option label="中台" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="后台" value="2" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="小程序" value="3" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<!-- 日志级别 -->
					<el-select style="margin-left: 10px;" v-model="state" placeholder="请选择日志级别" clearable
						@clear="empty" size="large">
						<el-option label="普通更新" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="重要更新" value="2" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
				<!-- 设置规则 -->
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large"
						@click="regulation"><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button>
				</div>
			</div>
			<div class="summation">
				<div class="hint">统计 : {{zong}}条</div>
				<div class="setting">
				</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 功能名称   -->
				<el-table-column fixed label="功能名称  " prop="title" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 功能目录  -->
				<el-table-column label="功能目录 " prop="catalogue" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 功能类型  -->
				<el-table-column label="功能类型 " prop="type" align="center" :show-overflow-tooltip='true'  :formatter="formatSex2">
				</el-table-column>
				<!-- 日志级别  -->
				<el-table-column label="日志级别 " prop="importanceLevel" align="center" :show-overflow-tooltip='true'  :formatter="formatSex1">
				</el-table-column>
				<!-- 更新时间 -->
				<el-table-column label="更新时间" prop="updateTime" align="center" :formatter="reach" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 发布人  -->
				<el-table-column label="发布人 " prop="adminName" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 操作 -->
				<el-table-column label="操作" align="center" :width="240" :show-overflow-tooltip='true'>
					<template #default="scope">
						<el-button size="default" @click.stop="copyreader(scope.row.id)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
							<el-button size="default" @click.stop="unit(scope.row.id)" class="bulur"><el-icon
									class="cancel">
									<Search />
								</el-icon>更新查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		WalletFilled,
		Van,
		ShoppingCartFull,
		Search,
		Download,
		Plus,
		EditPen
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount,
		onActivated
	} from 'vue';
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		logsList,
	} from "../../../utils/api";
	import {
		useStore
	} from "vuex";
	import qs from 'qs';
	import moments from 'moment'
	// import moments from 'moment'
	export default {
		name: "logsList",
		computed: {},
		setup() {
			// 判断开发状态
			let formatSex = (row, column, state) => {
				if (state == 1) {
					return "待开发";
				} else if (state == 2) {
					return "开发中";
				} else{
					return "已完成";
				}
			};
			//判断重要级别 
			let formatSex1 = (row, column, importanceLevel) => {
				if (importanceLevel == 1) {
					return "普通更新";
				} else if (importanceLevel == 2) {
					return "重要更新";
				}
			};
			//判断重要级别
			let formatSex2 = (row, column, type) => {
				if (type == 1) {
					return "中台";
				} else if (type == 2) {
					return "后台";
				}else{
					return "小程序";
				}
			};
			const route = useRoute();
			// 搜索按钮加载
			let formative = ref(false)
			// 成功弹窗
			let anew = ref(false)

			let quoth = ref('')
			//功能名称输入框
			let input = ref('')
			//功能类型选择
			let type =ref('')
			//
			let state = ref('')
			// 下拉隐藏
			let selectIt = ref()
			let closeIt = () => {
				selectIt.value.blur();
			}

			// 加载中
			const acting = ref(false)
			// 按钮禁用
			const jinyong = ref(false)
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					unitAbbreviation.value = ''
				}
			}

			// 导入
			// const error=(e)=>{
			//   console.log(e)
			// }
			const pleased = ref()




			// 数据总共条数
			let zong = ref(null)
			// let reach = (row, colum, cellValue) => {
			// 	if (typeof cellValue == 'number') {
			// 		return moments(cellValue * 1000).format().substring(0, 10)
			// 	}
			// }
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			// 列表数据循环
			const filterTableData = ref([])
			// 表格数据接口
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value
				}
				logsList(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						zong.value = res.data.data.updatePage.totalCount
						filterTableData.value = res.data.data.updatePage.list
						console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 分页获取积分的派发日志
			// 动态加载数据
			let branches = ref(6)
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				console.log(size)
				pagesize.value = size
				branches.value = size
				if (input.value !== '' || type.value !== '' || state.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				texts.value = '加载中'
				if (input.value !== '' || type.value !== '' || state.value !== '') {
					search()
				} else {
					list()
				}
				// console.log(currentPage.value)
				// console.log(branches.value )
			}


			// 加载中
			let texts = ref('加载中')
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				// translate.value=route.params.userId
				if(translate.value == 1){
				  texts.value='加载中'
				  list()
				  // 所有组织列表接口
				  // lang()
				}
				// if (translate.value == 1) {
				// 	a.value = ''
				// 	b.value = ''
				// 	unitAbbreviation.value = ''
				// 	serial.value = ''
				// 	name.value = ''
				// 	texts.value = '加载中'
				// 	branches.value = 6
				// 	currentPage.value = 1
				// 	list()
				// 	// 所有组织列表接口
				// 	lang()
				// }
			})

			let search1 = () => {
				formative.value = true
				search()
			}
			

			// 搜索框点击
			let search = () => {
				// 时间截止
				// 工会code
				// console.log(Hcode.value)
				// 会员编码框值
				// console.log(serial.value)
				// 姓名
				// console.log(designation.value)
				console.log(a.value)
				console.log(b.value)
				// 搜索接口
				let data = {
					limit: branches.value,
					page: currentPage.value,
					title:input.value,
					type:type.value, 
					importanceLevel:state.value,
				}
				logsList(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						zong.value = res.data.data.updatePage.totalCount
						filterTableData.value = res.data.data.updatePage.list
						// console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					} else if (res.data.code !== 0) {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			let a = ref('')
			let b = ref('')

			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
				}
				// console.log(e)
			}

			// 搜索组织列表接口
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 标识
			let unitAbbreviation = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unitName
				unitAbbreviation.value = node.unitAbbreviation
				visible.value = false
				console.log(node)
			}
			let treeData = ref([])
			const yes1 = () => {
				form.coding = options.value.unionName
				visible1.value = false;
			};


			onMounted(() => {
				// 获取当前页path
				// lang()
				list()
			})
			// const lang = () => {
			// 	ZClist().then((res) => {
			// 		texture.value = res.data.data.unitList
			// 		//  console.log(res)
			// 	})
			// 	// console.log('44657687')
			// }
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				label: 'unitName',
			}
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 搜索组织弹窗
			let visible = ref(false)
			// 添加跳转
			let regulation = () => {
				router.push('/logsAdd')
			}


			// 会员编码框
			let serial = ref('')
			// 搜索框变化值
			let orangutan = () => {
				// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
				//   list()
				//   console.log('空了')
				// }
				// console.log('变化了')
			}
			// 姓名输入框值
			let designation = ref('')

			// 时间选择
			let value1 = ref('')
			const whirl = ref(false)
			onMounted(() => {
				//选择工会下拉框数据
				// lang()
				//列表页数据
				list()
				// //头部积分数据
				// gration()
			})
			// 待办内容点击事件
			const router = useRouter()
			// 待办内容
			const tableData = [];
			// 头像加载失败
			const errorHandler = () => true;
			// 头部添加跳转
			let append=()=>{
				console.log(111)
			  router.push('/returnPoints')
			}
			// 编辑跳转
			const copyreader = (id) => {
				// console.log(id)
				sessionStorage.setItem('logsIds', id)
				router.push({
					name: 'logsEdit',
					params: {
						UserId: 1
					}
				})
			}
			// 功能查看
			const unit = (id) => {
				// console.log(id)
				sessionStorage.setItem('processIds', id)
				router.push({
					name: 'logsDetail',
					params: {
						UserId: 1
					}
				})
			}
			return {
				formative,
				search1,
				quoth,
				anew,
				selectIt,
				closeIt,
				// error,
				jinyong,
				numerical,

				immediUpdate,
				reach,
				filterTableData,
				// 分页
				zong,
				branches,
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 组织弹窗
				ability,
				texture,
				visible,
				// yes,
				dendrogram,
				defaultProps,
				multiple,
				yes1,
				treeData,
				// 会员编码框
				serial,
				// 搜索框
				search,
				// 姓名框变化值
				orangutan,
				// 会员姓名
				designation,
				// 时间选择
				whirl,
				value1,
				errorHandler,
				tableData,
				texts,
				append,
				regulation,//添加按钮
				copyreader,//编辑按钮
				unit,//功能查看按钮
				input,
				type,
				state,
				formatSex,
				formatSex1,
				formatSex2,
			};
		},
		components: {
			Search,
			WalletFilled,
			Van,
			ShoppingCartFull,
			Plus,
			EditPen,
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

	}

	.setting {
		display: flex;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.summation {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	// ::v-deep .el-scrollbar__thumb {
	//   display: none !important;
	// }
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>